var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 64",
      "enable-background": "new 0 0 64 64",
      "width": _vm.width,
      "height": _vm.height
    }
  }, [_c('g', {
    attrs: {
      "fill": _vm.fill
    }
  }, [_c('path', {
    attrs: {
      "d": "M58 36.2h-5.7c.4-1.1.6-2.3.6-3.5 0-5.7-4.7-10.3-10.5-10.3-.9 0-1.8.1-2.6.3V22c0-6.9-5.7-12.4-12.7-12.4-7 0-12.7 5.6-12.7 12.4v.7c-.9-.2-1.7-.3-2.6-.3C6 22.4 1.3 27 1.3 32.7 1.3 38.4 6 43 11.8 43H19v6.7c0 2.6 2.1 4.8 4.8 4.8H58c2.6 0 4.8-2.1 4.8-4.8V41c0-2.6-2.2-4.8-4.8-4.8zM4.8 32.7c0-3.8 3.1-6.8 7-6.8 1.5 0 2.9.4 4.1 1.3.6.4 1.5.4 2.1-.1.6-.5.8-1.3.6-2-.4-1-.6-2-.6-3.1 0-4.9 4.1-8.9 9.2-8.9s9.2 4 9.2 8.9c0 1.1-.2 2.1-.6 3.1-.3.7 0 1.5.6 2s1.4.5 2.1.1c1.2-.8 2.6-1.3 4.1-1.3 3.9 0 7 3.1 7 6.8 0 1.3-.4 2.5-1 3.5H23.7c-2.1 0-3.9 1.4-4.5 3.3h-7.5c-3.8 0-6.9-3-6.9-6.8zm54.5 17c0 .7-.6 1.3-1.3 1.3H23.7c-.7 0-1.3-.6-1.3-1.3V41c0-.7.6-1.3 1.3-1.3H58c.7 0 1.3.6 1.3 1.3v8.7z"
    }
  }), _c('path', {
    attrs: {
      "d": "M29.5 43.4c-1 0-1.8.8-1.8 1.8v.1c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-.1c-.1-1-.9-1.8-1.8-1.8z"
    }
  }), _c('path', {
    attrs: {
      "d": "M53.9 43.5h-4.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h4.7c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }